.reasoning-preset-container{
    position: absolute;
    bottom: 10vh;
    margin: 10px;
    width: 36vw;
    /* bottom: 11%;
    left: 12%;
    width: 85%; */
    display: grid;
    gap: 5% 1%;
}

/* Prevent card text overflow*/
.ant-card-meta-title {
    white-space: pre-line;
  }