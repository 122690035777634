.mindmap {
    width: 100%;
    height: 90%;
    max-width: 300;
    background-color: #fff;
}

.markdown-input {
    border-color: #A72841 !important;
    border-radius: 10px !important;
    margin-bottom: 8px;
}

.markdown-input:focus{
    box-shadow: 0 0 2vh rgba(0, 0, 0, 0.15);
}

.empty-mindmap line {
    display: none;
}

div.mm-toolbar {
    /* position: absolute;
    right: 0;
    bottom: 0; */
    border: 2px solid #b2adad;
    border-radius: .25rem;
    display: flex;
    justify-content: center; /* Centers the child divs horizontally */
    align-items: center; /* Centers the child divs vertically */
    width: 200px;
    padding: .25rem;
}

div.mm-toolbar-item {
    width: 20px;
    height: 20px;
}

div.mm-toolbar-item:focus {
    background-color: #b2adad;
}