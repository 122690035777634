.mask-feedback{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000;
    z-index: 10;
    opacity: 0.5;
    width: 100%;
    height: 100%;
}

.popup-wrapper-feedback{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 50%;
    width: 80%;
    background-color: #fff;
    z-index: 999;
    border-radius: 15px;
}

.feedback-title{
    padding-top: 2%;
    padding-left: 4%;
    font-size: large;
    color: black;
}

.feedback-box{
    padding-top: 2%;
    padding-left: 4%;
    padding-right: 4%;
    height: 40%;
}

.feedback-box-content:hover{
   border: 1px solid #A72841 !important;
}

.feedback-box-content:focus{
    border: 1px solid #A72841 !important;
}

.feedback-box-content{
    height: 100% !important;
}

.feedback-submit-button{
    position: absolute;
    top: 80%;
    right: 4%;
    background-color: #fff;
    border:1px solid rgb(168, 165, 165);
    border-radius: 5px;
    height: 10%;
    width: 15%;
}

.feedback-submit-button:hover{
    border:1px solid #A72841;
}


.feedback-cancle-button{
    position: absolute;
    top: 80%;
    right: 20%;
    background-color: #fff;
    border:1px solid rgb(168, 165, 165);
    border-radius: 5px;
    height: 10%;
    width: 15%;
}

.feedback-cancle-button:hover{
    border:1px solid #A72841;
}

/* .close-popup-btn{
    position: absolute;
    top: 1%;
    right: 1%;
}

.close-popup-btn:hover{
    color: #A72841;
} */
