.retrieval-chatbox-container {
    position: relative;
    display: flex;
    top: 4vh;
    height: 82vh;
    width: 93%;
}

.message-list {
    flex: 1;
    overflow-y: auto;
    max-height: 91vh;
    padding: 0;
    background-color: #FFFFFF;
    width: 100%;
    padding-bottom: 8%;
}


.textarea{
    position: fixed;
    bottom: 8vh;
    right: 17.5%;
    z-index: 9;
    padding: 1vh 2vh 1vh 2vh !important;
    display: flex;
    align-items: flex-end;
    width: 60%;
    height: 20%;
    border-color: #A72841;
    border-radius: 25px;
}

.textarea:hover{
    border-color: #A72841!important;
}
.textarea:focus{
    box-shadow: 0 0 1vh rgba(0, 0, 0, 0.15);
    border-color: #A72841!important;
}

.search-button {
    position: fixed;
    bottom: 8%;
    z-index: 9;
    right: 18.5%;
}

.list-item.chatbot-message {
    background-color: #FFF6F8;
}

.list-item.fake-message {
    height: 25vh!important;
}

.search-anchor {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 5px 0 5px;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    display: flex;
    align-items: flex-end;
}

.search-anchor .ant-input-textarea {
    flex: 1;
    margin-right: 16px;
}

.search-anchor .ant-btn {
    margin-bottom: 0;
    margin-left: 5px;
}



.list-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.list-item .ant-avatar {
   margin: 0 0 0 3vh;
}


.list-item .ant-list-item-meta-content  {
  text-align: left;
  padding-right: 5vh;
  font-size: 1.2em!important;
  margin-top: 3px;
}

.list-item.fake-message .ant-list-item-meta-content {
    padding-right: 0;
}

.clear-button-wrapper {
    display: flex;
    justify-content: center;
}

.clear-button {
    color:#A72841;
    border-radius: 3vh;
}

.clear-button:hover {
    border-color: #A72841!important;
    color: #A72841!important;
}

.opacity-block-top {
    position: fixed;
    bottom: 8%;
    width: 100%;
    height: 5%;
    background: #FFF;
    box-shadow: 0px -30px 20px 0px #FFF;
    filter: blur(5px);
}

.opacity-block-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10%;
    background-color: #FFF;
}

.user-message-description{
    color: #000;
}