.footer{
    position: fixed;
    width: 93%;
    justify-content: center;
    left: 7%;
    bottom: 4%;
    color: grey;
    font-weight: 300;
    font-size: small;
}
