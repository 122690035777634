.container{
    width: 7%;
    height: 91vh;
    background-color: #f7f7f7;
    left: 0;
}

.sider{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sider-item{
    position: relative;
    height: 75px;
    width: 75px;
    margin-top: 20px;
    text-decoration: none;
    font-size: small;
    border-radius: 10px;
    transition: background-color 0.5s;
}

.sub_item{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.svg{
    height: 30px;
    width: 30px;
    color: #F8F8f8;
}


