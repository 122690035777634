.App {
  text-align: center;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.app-main{
  display: flex;
  flex-direction: row;
}
