.subHeader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93%;
    height: 3.75vh;
    border-bottom: #f7f7f7 solid 0.25vh;
    position: absolute;
    left: 5%;
    top: 7vh;
}

