.analysing-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 91vh;
    width: 93%;
}

.analysing-input{
    width: 100%;
    height: 100%!important;
    border: #A72841 solid 1px !important;
    border-radius: 10px;
    padding-bottom: 10vh;
}
.analysing-input:focus{
    box-shadow: 0 0 2vh rgba(0, 0, 0, 0.15);
}

.analysing-left-section{
    width: 45%;
    height: 85%;
    border-radius: 10px;
    /* margin-top: 4%; */
}

.analysing-send-button {
    position:fixed;
    z-index: 9;
    bottom: 10%;
    left: 49%;
}

.analysing-output{
    width: 100%;
    height: 100%;
    margin-left: 1%;
    border: #A72841 solid 1px;
    border-radius: 10px;
    /* overflow: scroll; */
    text-align: left;
    padding: 0 1%;
}

.analysing-right-section{
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 85%;
    border-radius: 10px;
    background-color: #fff;
}


/* .clear-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    margin-bottom: 5vh;
} */

/* .analysing-clear-input-button-wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 15%;
    left: 32%;
} */

.analysing-clear-input-button-wrapper {
    position: relative;
    bottom: 6vh;
}

.feedback-icon {
    float:right
}
