.header {
    position: relative;
    height: 6.5vh;
    background-color: #A72841;
    border-bottom: 0.5vh solid #E32046;   
    display: flex;
    align-items: center;
}

.header-item{
    color: #FFF;
    position: absolute;
    left: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-text{
    margin-left: 6px;
    font-size: large;
}

.header-icon{
    width: 30px;
    height: 30px;
}


/* .header-sub{
    position: absolute;
    bottom: 0;
} */
/* 
.header-item{
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3vh;
    width: 150px;
    text-align: center;
    text-decoration: none;
    padding: 14px 16px;
    border-radius: 25px 25px 0 0;
    transition: background-color 0.3s;
} */


/* .header-sub:nth-child(1){
    left: 100px;
}

.header-sub:nth-child(2){
    left: 280px;
} */

/* .header-svg{
    width: 25px;
    height: 25px;
}

.header-icon{
    display: flex;
    align-items: center;
} */

/* .header-item_text{
    margin-left: 8px;
} */

/* .menu-container {
    height: 9vh;
} */

/* .menu-item {
    height: 9vh;
    font-size: medium;
    overflow: hidden;
} */