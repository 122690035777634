.landing-container {
    height: 80vh;
    position: relative;
}

.grid-container {
    position: fixed;
    bottom: 15vh;
    /* left: 23.5%;
    right: 8.5%; */
    width: 60%;
    right: 15.5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
    grid-auto-rows: auto; /* Rows adjust based on content */
    gap: 5% 1%; /* Gap between the cards as a percentage */
    padding: 2%; /* Optional padding for spacing */
}